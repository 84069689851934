import React from 'react'
import { NewsWrapper, MediaWrap, More, Trivia, CardWrap, Card, NewsContainer, FirstCard, Intro } from './atoms'
import { CmsContentService } from '@raysync/common/services'
import { Link } from '@raysync/common/components'
import { useLazyImg } from '@raysync/common/hooks'
const NewsCard = ({ title, date, slug, content, firstCard }) => {
  const imgRef = React.useRef(null)
  const imgSource = CmsContentService.getCoverImg(content)
  const intro = CmsContentService.getText(content).slice(0, 180)
  const { imgSrc } = useLazyImg({ imgUrl: imgSource, target: imgRef })
  const currentPackageName = process.env.GATSBY_PACKAGE_NAME

  if (firstCard) {
    return (
      <FirstCard ref={imgRef}>
        <Link to={`/news/${slug}/`} isInternal={currentPackageName === 'news'}>
          <img src={imgSrc} alt='' />
        </Link>
        <div>
          <Link to={`/news/${slug}/`} isInternal={currentPackageName === 'news'}>
            {title}
          </Link>
          <Intro>{intro}</Intro>
          <span>{date}</span>
        </div>
      </FirstCard>
    )
  }
  return (
    <Card ref={imgRef}>
      <MediaWrap>
        <img src={imgSrc} alt='' />
      </MediaWrap>
      <Trivia>
        <Link to={`/news/${slug}/`} isInternal={currentPackageName === 'news'}>
          {title}
        </Link>
        <p>{date}</p>
      </Trivia>
    </Card>
  )
}

const HotNews = ({ data }) => {
  const firstCardData = data[0] ? data[0].node : {}
  return (
    <NewsContainer>
      <NewsWrapper>
        <NewsCard
          firstCard
          slug={firstCardData.slug}
          title={firstCardData.title}
          date={firstCardData.legacyDate || firstCardData.createdAt}
          content={
            firstCardData.content && firstCardData.content.content
              ? firstCardData.content.content
              : firstCardData.tinyContent && firstCardData.tinyContent.tinyContent
          }
        />
        <CardWrap>
          {data.slice(1, 6).map(({ node }, index) => (
            <NewsCard
              key={index}
              slug={node.slug}
              title={node.title}
              date={node.legacyDate || node.createdAt}
              content={
                node.content && node.content.content
                  ? node.content.content
                  : node.tinyContent && node.tinyContent.tinyContent
              }
            />
          ))}
        </CardWrap>
      </NewsWrapper>
      <More to='/news/' isInternal={false}>
        {'查看更多 >'}
      </More>
    </NewsContainer>
  )
}
export default HotNews
