import React, { useRef, useState, useMemo } from 'react'
import { useHover, useMedia, useWindowSize } from '@raysync/common/hooks'
import { Navigation } from '@raysync/common/services'
import intl from 'react-intl-universal'
import { Button, Icon, IconType } from '@raysync/common/components'
import { TabContentWrap, MobileWrap, MobileItem, MobileContent, ExpandItem, Container, Item } from './atoms'
import FastSvg from '@raysync/common/components/svg/home/fast'

const ListItem = ({ onTrigger, children, active }) => {
  const ref = useRef()
  useHover(ref, onTrigger, true)
  const { width } = useWindowSize()
  const itemWidth = useMemo(() => {
    if (width) {
      const availWidth = (width >= 1400 ? width : 1400) - 520
      const itemWidth = availWidth / 7
      return itemWidth > 200 ? 200 : itemWidth
    }
    return 200
  }, [width])
  return (
    <Item ref={ref} itemWidth={itemWidth} active={active}>
      {children}
    </Item>
  )
}
const TabContent = ({ data }) => {
  let [active, setActive] = useState(0)
  let [expands, setExpands] = useState(new Array(data.length).fill(false))

  const toggleExpands = index => {
    let arr = [...expands]
    arr.splice(index, 1, !arr[index])
    setExpands(arr)
  }

  let isMobile = useMedia()
  if (isMobile) {
    return (
      <MobileWrap>
        {data.map((l, i) => (
          <MobileItem
            key={l.action}
            expanded={expands[i]}
            onClick={() => {
              toggleExpands(i)
            }}
          >
            <img src={l.mobilePic} alt={l.title} />
            <div>
              <ExpandItem expanded={expands[i]}>
                {expands[i] ? l.title : l.tag}
                <Icon type={IconType.ArrowRight} />
              </ExpandItem>
              <MobileContent>
                <p>{l.desc}</p>
                <ol>
                  <li>
                    <FastSvg />
                    {l.point1}
                  </li>
                  <li>
                    <FastSvg />
                    {l.point2}
                  </li>
                  <li>
                    <FastSvg />
                    {l.point3}
                  </li>
                  <li>
                    <FastSvg />
                    {l.point4}
                  </li>
                </ol>
                <Button id={l.id} onClick={() => Navigation.toSolution(l.action)}>
                  {intl.get('home.solution.button')}
                  <Icon type={IconType.ArrowRight} />
                </Button>
              </MobileContent>
            </div>
          </MobileItem>
        ))}
      </MobileWrap>
    )
  } else {
    return (
      <TabContentWrap>
        <Container>
          {data.map((l, i) => {
            return (
              <ListItem
                key={l.title}
                active={active === i}
                onTrigger={() => {
                  setActive(i)
                }}
              >
                <img src={l.pic} alt={l.title}></img>
                <div>
                  <h3>{active === i ? l.title : l.tag}</h3>
                  <span>{'了解更多>'}</span>
                  <p>{l.desc}</p>
                  <ol>
                    <li>
                      <FastSvg />
                      {l.point1}
                    </li>
                    <li>
                      <FastSvg />
                      {l.point2}
                    </li>
                    <li>
                      <FastSvg />
                      {l.point3}
                    </li>
                    <li>
                      <FastSvg />
                      {l.point4}
                    </li>
                  </ol>
                </div>
                <Button href={l.link} id={l.id} onClick={() => Navigation.toSolution(l.action)}>
                  {intl.get('home.solution.button')}
                </Button>
              </ListItem>
            )
          })}
        </Container>
      </TabContentWrap>
    )
  }
}

export default TabContent
