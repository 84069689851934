import React from 'react'

const SvgFeature6 = props => (
  <svg viewBox='0 0 56.38 62' {...props}>
    <defs>
      <style>
        {
          '.feature6_svg__cls-1,.feature6_svg__cls-2{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}.feature6_svg__cls-1{stroke:#252a3a}.feature6_svg__cls-2{stroke:#fd5c1f}'
        }
      </style>
    </defs>
    <g id='feature6_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='feature6_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path className='feature6_svg__cls-1' d='M1 1h11.03v8.85H1z' />
        <path className='feature6_svg__cls-2' d='M1 18.24h11.03v8.85H1zM44.35 1h11.03v8.85H44.35z' />
        <path className='feature6_svg__cls-1' d='M12.03 5.43h12.44' />
        <path
          className='feature6_svg__cls-1'
          transform='rotate(180 49.865 22.665)'
          d='M44.35 18.24h11.03v8.85H44.35z'
        />
        <path className='feature6_svg__cls-1' d='M44.35 22.66H31.91' />
        <path
          className='feature6_svg__cls-2'
          d='M44.35 5.43h-7.63a5.74 5.74 0 00-4.66 2.39l-7.93 12.45a5.71 5.71 0 01-4.65 2.39H12'
        />
        <path
          className='feature6_svg__cls-1'
          d='M1 34.91h11.03v8.85H1zM1 52.15h11.03V61H1zM44.35 34.91h11.03v8.85H44.35zM12.03 39.34h12.44'
        />
        <path className='feature6_svg__cls-1' transform='rotate(180 49.865 56.575)' d='M44.35 52.15h11.03V61H44.35z' />
        <path
          className='feature6_svg__cls-1'
          d='M44.35 56.57H31.91M44.35 39.34h-7.63a5.74 5.74 0 00-4.66 2.39l-7.93 12.45a5.71 5.71 0 01-4.65 2.39H12'
        />
      </g>
    </g>
  </svg>
)

export default SvgFeature6
