import React from 'react'
import intl from 'react-intl-universal'
import styled, { css } from 'styled-components'
import { useMedia } from '@raysync/common/hooks'
import { Media } from '@raysync/common/theme'
import Award1 from './images/award1.png'
import Award2 from './images/award2.png'
import Award3 from './images/award3.png'
import Award4 from './images/award4.png'
import Award5 from './images/award5.png'
import Award6 from './images/award6.png'
import Award7 from './images/award7.png'
import Award8 from './images/award8.png'
import Award9 from './images/award9.png'
import Award10 from './images/award10.png'
import Award11 from './images/award11.png'

const awardList = [
  {
    img: Award1,
    title: 'home.honor.item1',
  },
  {
    img: Award2,
    title: 'home.honor.item2',
  },
  {
    img: Award3,
    title: 'home.honor.item3',
  },
  {
    img: Award4,
    title: 'home.honor.item4',
  },
  {
    img: Award5,
    title: 'home.honor.item5',
  },
  {
    img: Award6,
    title: 'home.honor.item6',
  },
  {
    img: Award7,
    title: 'home.honor.item7',
  },
  {
    img: Award8,
    title: 'home.honor.item8',
  },
  {
    img: Award9,
    title: 'home.honor.item9',
  },
  {
    img: Award10,
    title: 'home.honor.item10',
  },
  {
    img: Award11,
    title: 'home.honor.item11',
  },
]
const newAwardList = typeof window === 'undefined' ? awardList : [...awardList, ...awardList]
const LIST_WIDTH = 300 * awardList.length
const MOBILE_LIST_WIDTH = 3.6 * awardList.length
export const AwardList = styled.div`
  margin-top: 56px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  ${Media.phone`
    margin-top: .5rem;
    flex-wrap: wrap;
    padding: 0;
  `}
`
export const AwardItem = styled.div`
  width: 280px;
  min-width: 280px;
  height: 320px;
  margin-right: 20px;
  border: 1px solid #f3f6f7;
  border-radius: 12px;
  background: linear-gradient(0deg, #fff 0%, #fff 100%);
  padding-top: 34px;
  transition: background 0.3s linear;
  img {
    height: 200px;
    max-width: 240px;
    display: block;
    margin: 0 auto 20px;
    path,
    circle {
      transition: all 0.3s linear;
    }
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #595c67;
    line-height: 1.4;
    transition: all 0.3s linear;
  }
  /* :hover {
    background: linear-gradient(0deg, #fd7440 0%, #fd5c1f 100%);
    box-shadow: 0px 10px 16px 4px rgba(253, 92, 31, 0.3);
    border-color: #fd5c1f;
    svg {
      path,
      circle {
        stroke: #fff;
      }
    }
    p {
      color: #fff;
    }
  } */
  ${Media.phone`
    width: 3.6rem;
    min-width: 3.6rem;
    height: 4rem;
    border-radius: .12rem;
    padding-top: .34rem;
    padding-bottom: .3rem;
    margin-bottom: .3rem;
    margin-right: .2rem;
    img {
      max-height: 2.34rem;
      margin-bottom: .4rem;
    }
    p {
      font-size: .24rem;
    }
  `}
`
const ViewPort = styled.div`
  width: 100%;
  height: 320px;
  overflow-x: hidden;
  position: relative;
`
const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  animation-name: loop;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-duration: 20s;
  transform: translate3d(0px, 0px, 0px);
  :hover {
    animation-play-state: paused;
  }
  @keyframes loop {
    0% {
      transform: translate3d(0px, 0px, 0px);
    }
    100% {
      transform: ${`translate3d(-${LIST_WIDTH}px, 0px, 0px)`};
    }
  }
  ${Media.phone`
    @keyframes loop {
      0% {
        transform: translate3d(0px, 0px, 0px);
      }
      100% {
        transform: ${`translate3d(-${MOBILE_LIST_WIDTH}rem, 0px, 0px)`};
      }
    }
  `}
`

export const Award = () => {
  const isMobile = useMedia()

  // if (isMobile) {
  //   return (
  //     <AwardList>
  //       {awardList.map((award, i) => (
  //         <AwardItem key={award.title + i + 10}>
  //           <img src={award.img} alt={award.title} />
  //           <p>{intl.getHTML(award.title)}</p>
  //         </AwardItem>
  //       ))}
  //     </AwardList>
  //   )
  // }

  return (
    <AwardList>
      <ViewPort>
        <Container>
          {newAwardList.map((award, i) => (
            <AwardItem key={award.title + i}>
              <img src={award.img} alt={award.title} />
              <p>{intl.getHTML(award.title)}</p>
            </AwardItem>
          ))}
        </Container>
      </ViewPort>
    </AwardList>
  )
}
