import React from 'react'

const SvgProduct6 = props => (
  <svg fill='none' width={38.8} height={48} viewBox='0 0 38.8 48' {...props}>
    <path
      d='M1.014 46.981H0c0 .56.454 1.014 1.014 1.014V46.98zm0-45.962V.005C.454.005 0 .46 0 1.02h1.014zm26.434 0l.77-.66a1.013 1.013 0 00-.77-.354V1.02zm10.338 12.07H38.8c0-.241-.087-.475-.244-.659l-.77.66zm0 33.892v1.014c.56 0 1.014-.454 1.014-1.014h-1.014zm-35.759 0V1.02H0V46.98h2.027zM1.013 2.032h26.435V.005H1.014v2.027zm25.665-.354L37.017 13.75l1.54-1.319L28.216.36l-1.539 1.318zM36.773 13.09V46.98H38.8V13.09h-2.027zm1.013 32.878H1.013v2.027h36.773v-2.027z'
      fill='#252A3A'
    />
    <path
      d='M11.4 26v2h4v2h-4v4h-2V24h6v2h-4zm11-2h-6v2h2v8h2v-8h2v-2zm4 2a1 1 0 110 2h-1v-2h1zm0-2h-3v10h2v-4h1a3 3 0 100-6zm-2-10l5 5v4h-2v-3.17L23.57 16H11.4v7h-2v-9h15z'
      fill='#FD5C1F'
    />
  </svg>
)

export default SvgProduct6
