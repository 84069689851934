import styled, { css } from 'styled-components'
import { Wrapper, Link } from '@raysync/common/components'
import { transition, Media, multiLineTextEllipsis } from '@raysync/common/theme'

export const CardWrap = styled.div`
  margin-left: 30px;
  ${Media.phone`
    margin-left: 0;
  `}
`

export const MediaWrap = styled.div`
  display: block;
  margin-right: 40px;
  a {
    width: 180px;
  }
  width: 180px;
  transition: all 0.3s linear;
  img {
    display: block;
    transition: all 0.3s linear;
    min-width: 180px;
    width: 180px;
    height: 100px;
    object-fit: cover;
  }
  ${Media.phone`
    width: 1.8rem;
    margin-right: .4rem;
    a {
      width: 1.8rem;
    }
    img {
      min-width: 1.8rem;
      width: 1.8rem;
      height: 1.6rem;
    }
  `}
`
export const Card = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #f3f6f7;
  border-radius: 8px;
  margin-right: 30px;
  :hover {
    box-shadow: 0px 10px 16px 4px rgba(204, 204, 204, 0.3);
    img {
      width: 220px;
      height: 120px;
    }
    ${MediaWrap} {
      height: 120px;
      width: 220px;
    }
  }
  ${Media.phone`
    margin-bottom: .2rem;
    :hover {
      img {
        width: 2.2rem;
        height: 1.6rem;
      }
      ${MediaWrap} {
        width: 2.2rem;
        height: 1.6rem;
      }
    }
  `}
`
export const Trivia = styled.div`
  ${({ theme: { color } }) => css`
    border-radius: 5px;
    transition: all 0.3s linear;
    a {
      color: #595c67;
      font-size: 16px;
      line-height: 1.5;
      margin: 26px 0 8px;
      ${multiLineTextEllipsis(1)};
    }
    p {
      font-size: 14px;
      color: #8796aa;
      line-height: 1;
    }
    ${Media.phone`
      a {
        font-size: .24rem;
        margin: .3rem 0 .1rem;
       ${multiLineTextEllipsis(2)};
      }
      p {
        font-size: .2rem;
      }
    `}
  `}
`
export const NewsContainer = styled.div``
export const Intro = styled.p`
  color: #636772;
  font-size: 14px;
  line-height: 24px;
  ${multiLineTextEllipsis(3)};
  ${Media.phone`
    font-size: .2rem;
  `}
`

export const NewsWrapper = styled(Wrapper)`
  max-width: 1340px;
  width: 1340px;
  display: flex;
  justify-content: space-between;
  ${Media.phone`
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
  `}
`
export const FirstCard = styled.div`
  ${({ theme: { color } }) => css`
    width: 660px;
    > a {
      overflow: hidden;
      width: 660px;
      height: 340px;
      border-radius: 8px 8px 0px 0px;
    }
    img {
      overflow: hidden;
      width: 100%;
      height: 340px;
      transition: ${transition(['transform'])};

      :hover {
        transform: scale(1.2);
      }
    }
    > div {
      a {
        color: ${color.primary};
        font-size: 20px;
        font-weight: 600;
        line-height: 1.2;
        ${multiLineTextEllipsis(1)};
        margin: 28px 0 12px 0;
      }
      span {
        display: inline-block;
        margin-top: 30px;
        color: #8796aa;
      }
    }
    ${Media.phone`
      width: 100%;
      border: 1px solid #F4F7F8;
      border-radius: 8px;
      margin-bottom: .3rem;
      > a {
        width: 100%;
        height: 3.6rem;
        border-radius: unset;
      }
      img {
        width: 100%;
        height: 3.6rem;
      }
      > div {
        padding: 0 .2rem .2rem;
        a {
          font-size: .3rem;
          margin: .1rem 0 .12rem;
        }
        h3 {
          font-size: .2rem;
          margin-top: .1rem;
        }
        span {
          margin-top: .3rem;
        }
      }
    `}
  `}
`

export const More = styled(Link)`
  ${({ theme: { color } }) => css`
    display: block;
    margin: 0 auto;
    color: ${color.secondary};
    font-size: 16px;
    color: #fd5c1f;
    line-height: 24px;
    text-align: center;
    margin-top: 20px;
    :hover {
      color: #dd501b;
    }
    ${Media.phone`
      display:block;
      text-align: center;
      margin-top: .2rem;
      font-size: .2rem;
    `}
  `}
`
