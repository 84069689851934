import React from 'react'
import { graphql } from 'gatsby'
import intl from 'react-intl-universal'
import { Swiper, ArrowSwiper, Button, ShowAtMobile, HideAtMobile } from '@raysync/common/components'
import Layout from '../components/layout'
import { useMedia } from '@raysync/common/hooks'
import { Navigation } from '@raysync/common/services'
import { VideoBanner, InviteBanner, RedHatBanner } from '../components/banners'
import { HomeFtpBanner, HomeFerryBanner, HomeCollaborationBanner } from '../components/banners/home-banner/banners'
import {
  ProductSection,
  SolutionSection,
  PartnerSection,
  NewsSection,
  Title,
  Desc,
  FeatureSection,
  FeatureContainer,
  FeatureItem,
  ProductContainer,
  ContactSection,
  HomeWrapper,
  AwardSection,
  AwardList,
  AwardItem,
  PartnerContainer,
  InterviewList,
  InterviewItem,
  BrandList,
  BrandItem,
  MoreCaseLink,
} from '../style/index.atoms'
import { ProductSwiper, MobileProductSwiper } from '../components/product-swiper/product-swiper'
import News from '../components/news/news'
import Tab from '../components/tab/index'
import { Award } from '../components/award/award'
import PhoneIcon from '@raysync/common/components/svg/phone1'
import Feature1 from '@raysync/common/components/svg/home/feature1'
import Feature2 from '@raysync/common/components/svg/home/feature2'
import Feature3 from '@raysync/common/components/svg/home/feature3'
import Feature4 from '@raysync/common/components/svg/home/feature4'
import Feature5 from '@raysync/common/components/svg/home/feature5'
import Feature6 from '@raysync/common/components/svg/home/feature6'
import FeatureBg1 from '../images/index/feature_bg1.png'
import FeatureBg2 from '../images/index/feature_bg2.png'
import FeatureBg3 from '../images/index/feature_bg3.png'
import FeatureBg4 from '../images/index/feature_bg4.png'
import FeatureBg5 from '../images/index/feature_bg5.png'
import FeatureBg6 from '../images/index/feature_bg6.png'
const featureList = [
  {
    svg: <Feature1 />,
    title: 'home.feature.item1.title',
    desc: 'home.feature.item1.desc',
    bg: FeatureBg1,
  },
  {
    svg: <Feature2 />,
    title: 'home.feature.item2.title',
    desc: 'home.feature.item2.desc',
    bg: FeatureBg2,
  },
  {
    svg: <Feature3 />,
    title: 'home.feature.item3.title',
    desc: 'home.feature.item3.desc',
    bg: FeatureBg3,
  },
  {
    svg: <Feature4 />,
    title: 'home.feature.item4.title',
    desc: 'home.feature.item4.desc',
    bg: FeatureBg4,
  },
  {
    svg: <Feature5 />,
    title: 'home.feature.item5.title',
    desc: 'home.feature.item5.desc',
    bg: FeatureBg5,
  },
  {
    svg: <Feature6 />,
    title: 'home.feature.item6.title',
    desc: 'home.feature.item6.desc',
    bg: FeatureBg6,
  },
]

const Index = props => {
  const {
    location,
    data: { hotSpot, logos, interviewData },
  } = props

  const isMobile = useMedia()

  const logoCountOnRow = isMobile ? 3 : 6

  const [interval, setInterval] = React.useState(8000)

  return (
    <Layout location={location} showH1={false} pageType='home' filled>
      <Swiper interval={interval} swiperNavType='defaultHome'>
        <VideoBanner onPlayChange={playing => setInterval(playing ? 800000 : 8000)} />
        <HomeFerryBanner height={600} isHome />
        <HomeFtpBanner height={600} isHome />
        <HomeCollaborationBanner height={600} isHome />
        <RedHatBanner />
      </Swiper>
      <FeatureSection>
        <Title bottom={20}>{intl.get('home.feature.title')}</Title>
        <Desc>{intl.getHTML('home.feature.desc')}</Desc>
        <FeatureContainer>
          <HideAtMobile>
            {featureList.map(item => (
              <FeatureItem key={item.title}>
                <img src={item.bg} alt={intl.get(item.title)} />
                <div>
                  {item.svg}
                  <h3>{intl.get(item.title)}</h3>
                  <p>{intl.get(item.desc)}</p>
                </div>
              </FeatureItem>
            ))}
          </HideAtMobile>
          <ShowAtMobile>
            <Swiper swiperNavType='dot'>
              {featureList.map(item => (
                <FeatureItem key={item.title}>
                  <img src={item.bg} alt={intl.get(item.title)} />
                  <div>
                    {item.svg}
                    <h3>{intl.get(item.title)}</h3>
                    <p>{intl.get(item.desc)}</p>
                  </div>
                </FeatureItem>
              ))}
            </Swiper>
          </ShowAtMobile>
        </FeatureContainer>
      </FeatureSection>

      <ProductSection>
        <Title bottom={20}>{intl.get('home.product.title')}</Title>
        <Desc>{intl.getHTML('home.product.desc')}</Desc>
        <ProductContainer>
          <HideAtMobile>
            <ProductSwiper />
          </HideAtMobile>
          <ShowAtMobile>
            <MobileProductSwiper />
          </ShowAtMobile>
        </ProductContainer>
      </ProductSection>
      <SolutionSection>
        <Title>{intl.get('home.solution.title')}</Title>
        <Desc top={20} bottom={70}>
          {intl.getHTML('home.solution.desc')}
        </Desc>
        <Tab />
      </SolutionSection>
      <ContactSection>
        <HomeWrapper>
          <p>{intl.get('home.apply-banner.title')}</p>
          <Button hasRadius color='white' rel='nofollow noopener noreferrer'>
            <PhoneIcon />
            {intl.get('pricing.phone')}
          </Button>
        </HomeWrapper>
      </ContactSection>
      <AwardSection>
        <Title>{intl.get('home.honor.title')}</Title>
        <Award />
      </AwardSection>
      <PartnerSection>
        <PartnerContainer>
          <HomeWrapper>
            <Title>{intl.get('home.partner.title')}</Title>
            <ShowAtMobile>
              <ArrowSwiper>
                {interviewData.nodes.map(node => (
                  <InterviewItem key={node.name}>
                    <img src={node.logo.fluid.src} alt={node.name} />
                    <p>{node.content.content}</p>
                    {node.link ? (
                      <Button onClick={() => Navigation.to({ link: node.link, isInternal: false })}>查看专访</Button>
                    ) : null}
                  </InterviewItem>
                ))}
              </ArrowSwiper>
            </ShowAtMobile>
            <HideAtMobile>
              <InterviewList>
                {interviewData.nodes.map(node => (
                  <InterviewItem key={node.name}>
                    <p>{node.content.content}</p>
                    <div>
                      <img src={node.logo.fluid.src} alt={node.name} />
                      {node.link ? (
                        <Button onClick={() => Navigation.to({ link: node.link, isInternal: false })}>查看专访</Button>
                      ) : null}
                    </div>
                  </InterviewItem>
                ))}
              </InterviewList>
            </HideAtMobile>

            <BrandList>
              {logos.nodes.map((logo, index) => {
                const hideBorderBottomCount =
                  logos.nodes.length % logoCountOnRow === 0 ? logoCountOnRow : logos.nodes.length % logoCountOnRow
                return (
                  <BrandItem key={index} hideBorderBottom={index >= logos.nodes.length - hideBorderBottomCount}>
                    <img src={logo.source.fluid.src} alt={logo.name} />
                  </BrandItem>
                )
              })}
            </BrandList>
            <MoreCaseLink to='/example/'>{'更多案例应用说明，点击查看 >'}</MoreCaseLink>
          </HomeWrapper>
        </PartnerContainer>
      </PartnerSection>
      <NewsSection>
        <Title bottom={60}>{intl.get('home.news.title')}</Title>
        <News data={hotSpot.edges}></News>
      </NewsSection>
      <Swiper>
        <InviteBanner type='home' />
      </Swiper>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query {
    hotSpot: allContentfulNews(
      limit: 5
      sort: { order: DESC, fields: createdAt }
      filter: { slug: { nin: "null" }, content: { content: { nin: "" } }, isBlog: { ne: true } }
    ) {
      edges {
        node {
          slug
          title
          legacyDate(formatString: "YYYY-MM-DD")
          createdAt(formatString: "YYYY-MM-DD")
          content {
            content
          }
          tinyContent {
            tinyContent
          }
        }
      }
    }
    logos: allContentfulLogo(sort: { fields: rank, order: ASC }) {
      nodes {
        source {
          fluid {
            src
          }
        }
        rank
        name
      }
    }
    interviewData: allContentfulInterview(sort: { fields: rank, order: ASC }) {
      nodes {
        logo {
          fluid {
            src
          }
        }
        content {
          content
        }
        link
        name
        author
        jobTitle
      }
    }
  }
`
