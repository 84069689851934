import React from 'react'

const SvgProduct3 = props => (
  <svg viewBox='0 0 44.97 47.35' {...props}>
    <defs>
      <style>
        {
          '.product3_svg__cls-1,.product3_svg__cls-2{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}.product3_svg__cls-1{stroke:#252a3a}.product3_svg__cls-2{stroke:#fd5c1f}'
        }
      </style>
    </defs>
    <g id='product3_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='product3_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='product3_svg__cls-1'
          d='M36.81 5.77H4.58A3.58 3.58 0 001 9.35v33.42a3.58 3.58 0 003.58 3.58h35.81A3.58 3.58 0 0044 42.77V17.71'
        />
        <path className='product3_svg__cls-1' d='M32.32 11.13l5.07-5.07L32.32 1' />
        <path className='product3_svg__cls-2' d='M22.48 16.52v20.29M32 24.87v11.94M12.94 24.87v11.94' />
      </g>
    </g>
  </svg>
)

export default SvgProduct3
