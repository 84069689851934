import React from 'react'

const SvgProduct2 = props => (
  <svg viewBox='0 0 47.35 47.35' {...props}>
    <defs>
      <style>
        {
          '.product2_svg__cls-1,.product2_svg__cls-2{fill:none;stroke-linecap:round;stroke-miterlimit:2;stroke-width:2px}.product2_svg__cls-1{stroke:#fd5c1f}.product2_svg__cls-2{stroke:#252a3a}'
        }
      </style>
    </defs>
    <g id='product2_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='product2_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='product2_svg__cls-1'
          d='M16.87 23.61V7.8a6.81 6.81 0 0113.61 0v2.28M30.48 23.68v15.87a6.81 6.81 0 01-13.61 0v-2.3'
        />
        <path
          className='product2_svg__cls-2'
          d='M23.68 30.48H7.79a6.81 6.81 0 010-13.61h2.27M23.68 16.87h15.86a6.81 6.81 0 110 13.61h-2.18'
        />
      </g>
    </g>
  </svg>
)

export default SvgProduct2
