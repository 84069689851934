import React from 'react'

const SvgProduct4 = props => (
  <svg viewBox='0 0 47.35 47.35' {...props}>
    <defs>
      <style>
        {
          '.product4_svg__cls-1,.product4_svg__cls-2{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}.product4_svg__cls-1{stroke:#fd5c1f}.product4_svg__cls-2{stroke:#252a3a}'
        }
      </style>
    </defs>
    <g id='product4_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='product4_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path className='product4_svg__cls-1' d='M31.24 24.94h15.11M38.8 17.38l7.55 7.56-7.55 7.56' />
        <path className='product4_svg__cls-2' d='M19.9 1v25.2c0 8.49-7.77 17.09-15.12 20.15' />
        <path
          className='product4_svg__cls-2'
          d='M46.35 11.08v-6.3A3.78 3.78 0 0042.57 1H4.78A3.78 3.78 0 001 4.78v37.79a3.78 3.78 0 003.78 3.78h37.79a3.78 3.78 0 003.78-3.78v-4.4'
        />
      </g>
    </g>
  </svg>
)

export default SvgProduct4
