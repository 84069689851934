import React from 'react'

import intl from 'react-intl-universal'
import { Link, Icon, IconType } from '@raysync/common/components'
import { useWindowSize } from '@raysync/common/hooks'
import { Container, Wrapper, Box, IconWrap, MobileWrap, MobileContent, MobileItem, ExpandItem } from './atoms'
import Product1 from '@raysync/common/components/svg/home/product1'
import Product2 from '@raysync/common/components/svg/home/product2'
import Product3 from '@raysync/common/components/svg/home/product3'
import Product4 from '@raysync/common/components/svg/home/product4'
import Product6 from '@raysync/common/components/svg/home/product6'
import Product7 from '@raysync/common/components/svg/home/product7'
import ArrowIcon from '@raysync/common/components/svg/home/arrow'
import CircleImg from './circle.png'

const productList = [
  {
    title: 'home.product1.title',
    desc: 'home.product1.desc',
    svg: <Product1 />,
    index: '- 01 -',
    link: '/products/file/',
    id: 'Products-LFT',
  },
  {
    title: 'ferry.banner.title',
    desc: 'ferry.banner.desc',
    svg: <Product7 />,
    index: '- 02 -',
    link: '/solutions/raysync-ferry/',
    id: 'Products-Ferry',
  },
  {
    title: 'ftp.banner.title',
    desc: 'ftp.banner.desc',
    svg: <Product6 />,
    index: '- 03 -',
    link: '/solutions/ftp-alternative-upgrade/',
    id: 'Products-ftp-alternative-upgrade',
  },
  {
    title: 'home.product2.title',
    desc: 'home.product2.desc',
    svg: <Product2 />,
    index: '- 04 -',
    link: '/products/sdk/',
    id: 'Products-SDK',
  },
  {
    title: 'home.product3.title',
    desc: 'home.product3.desc',
    svg: <Product3 />,
    index: '- 05 -',
    link: '/products/vpn/',
    id: 'Products-Proxy',
  },
  {
    title: 'home.product4.title',
    desc: 'home.product4.desc',
    svg: <Product4 />,
    index: '- 06 -',
    link: '/products/ftp/',
    id: 'Products-FTP',
  },
]

const arr =
  typeof window === 'undefined'
    ? productList
    : [
        productList[1],
        productList[2],
        productList[3],
        productList[4],
        productList[5],
        productList[0],
        productList[1],
        productList[2],
        productList[3],
        productList[4],
        productList[5],
        productList[0],
        productList[1],
        productList[2],
        productList[3],
        productList[4],
      ]

export const MobileProductSwiper = () => {
  const [expands, setExpands] = React.useState(new Array(productList.length).fill(false))
  const toggleExpands = index => {
    let arr = [...expands]
    arr.splice(index, 1, !arr[index])
    setExpands(arr)
  }
  return (
    <MobileWrap>
      {productList.map((l, i) => (
        <MobileItem key={l.title} expanded={expands[i]} onClick={() => toggleExpands(i)}>
          <ExpandItem expanded={expands[i]}>
            <div>
              {l.svg}
              <span>{intl.get(l.title)}</span>
            </div>
            <Icon type={IconType.ArrowRight} />
          </ExpandItem>
          <MobileContent>
            <p>{intl.get(l.desc)}</p>
            <div>
              <span>{l.index}</span>
              <Link to={l.link} isInternal id={l.id}>
                {intl.get(`home.product4.button`)} <ArrowIcon />
              </Link>
            </div>
          </MobileContent>
        </MobileItem>
      ))}
    </MobileWrap>
  )
}

export const ProductSwiper = () => {
  const lock = React.useRef(false)
  const [middleIndex, setMiddleIndex] = React.useState(5)
  const [duration, setDuration] = React.useState(0)
  const [circleDuration, setCircleDuration] = React.useState(0)
  const { width } = useWindowSize()
  const itemWidth = React.useMemo(() => {
    if (width) {
      const availWidth = (width >= 1400 ? width : 1400) - 235
      return availWidth / 5
    }
    return 337
  }, [width])
  const handleClick = i => {
    if (!lock.current) {
      lock.current = true
      setMiddleIndex(i)
      setDuration(1000)
      setCircleDuration(2000)
      setTimeout(() => {
        setDuration(0)
        lock.current = false
        if (i <= 4) {
          setMiddleIndex(i + 6)
        } else if (i >= 11) {
          setMiddleIndex(i - 6)
        }
      }, 1000)
      setTimeout(() => {
        setCircleDuration(0)
      }, 1000)
    }
  }
  const handleCircleClick = e => {
    e.stopPropagation()
    setCircleDuration(1000)
    setTimeout(() => {
      setCircleDuration(0)
    }, 1000)
  }
  const x = -(itemWidth + 30) * (middleIndex - 2)
  React.useEffect(() => {
    const timer = setInterval(() => {
      handleClick(middleIndex + 1)
    }, 6000)
    return () => {
      clearInterval(timer)
    }
  }, [middleIndex])

  return (
    <Container key={arr.length}>
      <Wrapper x={x} duration={duration}>
        {arr.map((item, i) => (
          <Box
            key={i + item.id}
            width={itemWidth}
            active={middleIndex === i}
            duration={duration}
            onClick={() => {
              handleClick(i)
            }}
          >
            <div>
              <IconWrap
                duration={circleDuration}
                onClick={e => {
                  if (middleIndex === i) {
                    handleCircleClick(e)
                  }
                }}
              >
                <span key={middleIndex + item.id}>{item.svg}</span>
                <img src={CircleImg} alt='circle' />
              </IconWrap>

              <h3>{intl.get(item.title)}</h3>
              <p>{intl.get(item.desc)}</p>
              <span>{item.index}</span>
            </div>
            <Link to={item.link} isInternal id={item.id}>
              {intl.get(`home.product4.button`)} <ArrowIcon />
            </Link>
          </Box>
        ))}
      </Wrapper>
    </Container>
  )
}
