import styled, { css } from 'styled-components'
import { transition, Media } from '@raysync/common/theme'
import { Wrapper, LgWrapper, Link } from '@raysync/common/components'
import ConcactBg from '../images/index/bg3.png'
import PartnerBg1 from '../images/index/partner_bg1.png'
import PartnerBg2 from '../images/index/partner_bg2.png'
import QuoteLeftImg from '../images/index/quote_left.png'
import QuoteRightImg from '../images/index/quote_right.png'

export const HomeWrapper = styled(Wrapper)`
  max-width: 1340px;
  width: 1340px;
`

export const SolutionSection = styled.div`
  padding: 80px 0 0;
  background: #252a3a;
  > h2,
  > p {
    color: #fff;
  }
  ${Media.phone`
    padding-top: .8rem;
    > h2,
    > p {
      padding-left:  .75rem;
      padding-right:  .75rem;
    }
  `};
`

export const NewsSection = styled(HomeWrapper)`
  padding: 60px 0 84px;
  ${Media.phone`
    padding: .8rem 0.4rem .6rem;
  `}
`

export const Title = styled.h2`
  font-size: 36px;
  color: #252a3a;
  line-height: 1.2;
  text-align: center;
  padding-top: ${p => (p.top ? p.top + 'px' : 0)};
  padding-bottom: ${p => (p.bottom ? p.bottom + 'px' : 0)};
  ${Media.phone`
     font-size: .42rem;
      padding-top: ${p => p.top / 100 + 'rem'};
      padding-bottom: ${p => p.bottom / 100 + 'rem'};
  `}
`
export const Desc = styled.p`
  text-align: center;
  font-size: 16px;
  color: #636772;
  line-height: 24px;
  padding-top: ${p => p.top + 'px'};
  padding-bottom: ${p => p.bottom + 'px'};
  ${Media.phone`
      font-size: .24rem;
      line-height: 2;
      padding-top: ${p => p.top / 100 + 'rem'};
      padding-bottom: ${p => p.bottom / 100 + 'rem'};
  `}
`

export const FeatureSection = styled(LgWrapper)`
  margin-left: auto;
  padding: 80px 0 60px;
  ${Desc} {
    max-width: 900px;
    margin: 0 auto;
  }
  ${Media.phone`
      padding: .8rem .75rem .6rem;
  `}
`
export const FeatureContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 52px;
  padding: 0 30px;
  ${Media.phone`
      padding: 0;
      margin-top: .5rem;
  `}
`
export const FeatureItem = styled.div`
  position: relative;
  overflow: hidden;
  width: 420px;
  height: 260px;
  background: #ffffff;
  border: 1px solid #f3f6f7;
  border-radius: 12px;
  margin-bottom: 40px;
  transition: ${transition(['box-shadow'])};
  div {
    position: absolute;
    z-index: 1;
    padding: 38px 30px;
    width: 100%;
    svg {
      display: block;
      height: 62px;
      margin: 0 auto;
      opacity: 1;
      transition: all 0.3s linear;
    }
    h3 {
      color: #252a3a;
      line-height: 1;
      text-align: center;
      margin: 24px 0 14px;
      font-weight: bold;
      font-size: 20px;
      transition: all 0.3s linear;
    }
    p {
      font-size: 14px;
      line-height: 24px;
      color: #636772;
      text-align: center;
      transition: all 0.3s linear;
    }
  }
  img {
    position: absolute;
    left: 0;
    top: 260px;
    transition: all 0.3s linear;
  }
  ${Media.desktop`
    :hover {
    box-shadow: 0px 10px 16px 4px rgba(253, 92, 31, 0.3);
    border: none;
    svg {
      height: 0;
      opacity: 0;
    }
    img {
      top: 1px;
    }
    p,
    h3 {
      color: #fff;
    }
    h3 {
      font-size: 24px;
    }
  }
  `}
  ${Media.phone`
    width: 6rem;
    height: 3.6rem;
    margin-bottom: .4rem;
    div {
      padding: .4rem;
      svg {
        height: 1rem;
      }
      h3 {
        margin: .2rem 0;
        font-size: .3rem;
      }
      p {
        font-size: .24rem;
        line-height: 1.5;
      }
    }
  `}
`
export const ProductSection = styled.div`
  padding: 80px 50px 50px;
  background: #f7f7f7;
  ${Media.phone`
    padding: .8rem .4rem .6rem;
  `}
`
export const ProductContainer = styled.div`
  margin-top: 40px;
  height: 440px;
  ${Media.phone`
    margin-top: .4rem;
    height: unset;
  `}
`
export const ProductItem = styled.div`
  width: 340px;
  height: 320px;
  background: #666;
  display: inline-block;
`
export const ContactSection = styled.div`
  background: url(${ConcactBg}) 100% 100%/100% 100%;
  > div {
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 30px;
      color: #fff;
    }
    svg {
      transform: scale(0.8);
      margin-right: 8px;
      path {
        fill: ${p => p.theme.color.secondary};
      }
    }
  }
  ${Media.phone`
    > div {
      height: 2.6rem;
      flex-direction: column;
      justify-content: center;
      p {
        text-align: center;
        max-width: 5rem;
        font-size: .3rem;
        margin-bottom: .3rem;
      }
      button {
        height: .5rem;
        line-height: .5rem;
      }
      svg {
        margin-right: .08rem;
      }
    }
  `}
`
export const AwardSection = styled.div`
  padding: 80px 0;
  ${Media.phone`
    padding: .8rem .4rem .3rem;
  `}
`
export const AwardList = styled.div`
  margin-top: 56px;
  display: flex;
  justify-content: space-between;
  ${Media.phone`
    margin-top: .5rem;
    flex-wrap: wrap;
  `}
`
export const AwardItem = styled.div`
  width: 200px;
  height: 240px;
  border: 1px solid #f3f6f7;
  border-radius: 12px;
  background: linear-gradient(0deg, #fff 0%, #fff 100%);
  padding-top: 34px;
  transition: all 0.3s linear;
  svg {
    height: 88px;
    display: block;
    margin: 0 auto 20px;
    path,
    circle {
      transition: all 0.3s linear;
    }
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #595c67;
    line-height: 1.4;
    transition: all 0.3s linear;
  }
  :hover {
    background: linear-gradient(0deg, #fd7440 0%, #fd5c1f 100%);
    box-shadow: 0px 10px 16px 4px rgba(253, 92, 31, 0.3);
    border-color: #fd5c1f;
    svg {
      path,
      circle {
        stroke: #fff;
      }
    }
    p {
      color: #fff;
    }
  }
  ${Media.phone`
    width: 3.2rem;
    height: 2.6rem;
    border-radius: .12rem;
    padding-top: .34rem;
    padding-bottom: .3rem;
    margin-bottom: .3rem;
    svg {
      height: .88rem;
      margin-bottom: .1rem;
    }
    p {
      font-size: .24rem;
    }
  `}
`

export const PartnerSection = styled.div`
  background-color: #f7f7f7;
`
export const PartnerContainer = styled.div`
  padding: 80px 0;
  background: url(${PartnerBg1}) left 50px top 38px no-repeat, url(${PartnerBg2}) right 50px top 38px no-repeat;
  ${Media.phone`
    background: none;
    padding: .8rem 0 .6rem;
    > div {
      padding-left: 0;
      padding-right: 0;

    }
  `}
`
export const InterviewList = styled.div`
  display: flex;
  justify-content: space-between;
`
export const InterviewItem = styled.div`
  margin-top: 56px;
  margin-bottom: 30px;
  width: 658px;
  height: 280px;
  background: #fff;
  border-radius: 8px;
  transition: all 0.3s linear;
  p {
    padding: 54px 48px 58px;
    font-size: 16px;
    color: #595c67;
    line-height: 30px;
    min-height: 132px;
    transition: ${transition(['color', 'font-weight'])};
  }
  div {
    padding: 0 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      transition: all 0.3s linear;
      border-radius: 20px;
      min-width: 140px;
      :hover {
        box-shadow: 0px 10px 16px 4px rgba(253, 92, 31, 0.3);
      }
    }
  }
  :hover {
    box-shadow: 0px 10px 16px 4px rgba(204, 204, 204, 0.3);
    p {
      font-weight: bold;
      color: #252a3a;
      background: url(${QuoteLeftImg}) left 30px top 30px no-repeat,
        url(${QuoteRightImg}) right 28px bottom 40px no-repeat;
    }
  }
  ${Media.phone`
    position: relative;
    width: 100%;
    height: unset;
    margin-top: .5rem;
    margin-bottom: .3rem;
    padding: .5rem .48rem .5rem;
    img {
      display: block;
      margin: 0 auto .3rem;
    }
    p {
      padding: 0;
      font-size: .24rem;
      line-height: 1.5;
      min-height: unset;
      color: #595C67;
    }
    button {
      min-width: 2.9rem;
      border-radius: .25rem;
      height: .5rem;
      line-height: .5rem;
      display: block;
      margin: .6rem auto 0;
    }
  `}
`
export const BrandList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0 73px;
  ${Media.phone`
    padding: .2rem .4rem .6rem;
  `}
`

export const BrandItem = styled.div`
  position: relative;
  width: 223px;
  height: 94px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  transition: all 0.3s linear;

  img {
    transition: all 0.3s linear;
  }
  :hover {
    img {
      transform: scale(1.05);
    }
    background: #ffffff;
    box-shadow: 7px 7px 16px 4px rgba(204, 204, 204, 0.3);
  }
  ${p =>
    p.hideBorderBottom &&
    css`
      border-bottom: none;
    `}
  ${Media.desktop`
    &:nth-child(6n) {
      border-right: none;
    }
  `}
  ${Media.phone`
    width: 33%;
    img {
      max-width: 70%;
    }
    &:nth-child(3n) {
      border-right: none;
    } 
  `}
`
export const MoreCaseLink = styled(Link)`
  display: block;
  margin: 0 auto;
  color: ${p => p.theme.color.secondary};
  font-size: 16px;
  text-align: center;
  :hover {
    color: #dd501b;
  }
  ${Media.phone`
    font-size: .2rem;
    margin-top: .5rem;
  `}
`
