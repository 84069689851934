import React from 'react'

const SvgFeature1 = props => (
  <svg viewBox='0 0 71.03 62' {...props}>
    <defs>
      <style>
        {
          '.feature1_svg__cls-1,.feature1_svg__cls-2{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}.feature1_svg__cls-1{stroke:#252a3a}.feature1_svg__cls-2{stroke:#fd5c1f}'
        }
      </style>
    </defs>
    <g id='feature1_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='feature1_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path className='feature1_svg__cls-1' d='M35.52 1v18.86M28.33 7.08L35.52 1M42.71 7.08L35.52 1' />
        <path className='feature1_svg__cls-2' d='M1 36.27h18.86M7.08 43.46L1 36.27M7.08 29.08L1 36.27' />
        <path
          className='feature1_svg__cls-1'
          d='M11.9 31.81V21.69l23.62 10.37V61L11.9 50.78v-9.9M11.9 21.69l19.42-8.12'
        />
        <path className='feature1_svg__cls-2' d='M70.03 36.27H51.17M63.95 43.46l6.08-7.19M63.95 29.08l6.08 7.19' />
        <path
          className='feature1_svg__cls-1'
          d='M59.14 31.81V21.69L35.52 32.06V61l23.62-10.22v-9.9M59.14 21.69l-19.42-8.12'
        />
      </g>
    </g>
  </svg>
)

export default SvgFeature1
