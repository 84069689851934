import React from 'react'

const SvgFeature5 = props => (
  <svg viewBox='0 0 47 62' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <g data-name='\u56FE\u5C42 1' fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth={2}>
        <path d='M32.5 1H10L1 35.5h12L7 61l39-42H28z' stroke='#252a3a' />
        <path d='M17.5 11.5l-3 12' stroke='#fd5c1f' />
      </g>
    </g>
  </svg>
)

export default SvgFeature5
