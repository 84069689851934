import styled, { css } from 'styled-components'
import { Media, transition } from '@raysync/common/theme'

export const TabWrap = styled.div`
  width: 100%;
`

export const TabHeader = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
`

export const TabContent = styled.div`
  ${({ theme: { color } }) => css`
    margin-top: 40px;
    width: 100%;
    height: 300px;
    background-color: ${color.panel};
  `}
`

export const TabTitle = styled.span`
  ${({ theme: { color } }) => css`
    display: inline-block;
    margin: 0 32px;
    padding: 18px 10px;
    font-size: 20px;
    line-height: 1;
    ${p =>
      p.active &&
      css`
        border-bottom: 3px solid ${color.secondary};
      `}
    ${Media.phone`
      font-size: .36rem;
      padding: .28rem 0;
      min-width: 40%;
      text-align: center;
      margin: 0;
      & + & {
        margin-left: .2rem;
      }
    `}
  `}
`

export const TabContentWrap = styled.div`
  display: flex;
`

export const MobileWrap = styled.div``

export const MobileContent = styled.div`
  ${({ theme: { Hack } }) => css`
    padding: 0 0.75rem;
    > p {
      padding-top: 0.1rem;
      color: #fff;
      font-size: 0.24rem;
      line-height: 0.36rem;
    }
    > button {
      display: block;
      margin-bottom: 0.3rem;
      min-width: 1.8rem;
      height: 0.5rem;
      line-height: 0.5rem;
      border-radius: 0.25rem;
    }
    > ol {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.15rem;
      margin-bottom: 0.3rem;
      li {
        display: block;
        color: #fff;
        width: 50%;
        font-size: 0.24rem;
        position: relative;
        line-height: 0.44rem;
        padding: 0;
        &:before {
          display: none;
        }
        svg {
          width: 0.24rem;
          margin-right: 0.08rem;
          path {
            fill: ${p => p.theme.color.secondary};
          }
        }
      }
    }

    ${Hack.mobileForeign`
      > ol {
        margin-top:5px;
        li {
          width: 100%;
          line-height: .48rem;
        }
      }
    `}
  `}
`
export const MobileItem = styled.div`
  position: relative;
  box-shadow: rgba(37, 42, 58, 0.12) 0px 0px 8px 0px;
  width: 100%;
  overflow: hidden;
  max-height: ${p => (p.expanded ? '12rem' : '1.2rem')};
  transition: ${transition('max-height')};
  img {
    height: ${p => (p.expanded ? '100%' : '1.2rem')};
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    transition: ${transition('height')};
  }
  > div {
    position: relative;
  }
`

export const ExpandItem = styled.div`
  line-height: 1.2rem;
  height: 1.2rem;
  font-size: 0.3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  svg {
    width: 20px;
    height: 36px;
    transform: ${p => (p.expanded ? 'rotate(-90deg)' : 'rotate(90deg)')};
    transition: ${transition('transform')};
  }
`
export const ArrowIcon = styled.i`
  display: block;
  font-style: normal;
  position: relative;
  color: #b6bec6;
  width: 40px;
  height: 20px;
  ${p => p.active && 'transform: rotate(180deg)'};
  :before,
  :after {
    content: '';
    pointer-events: none;
    height: 2px;
    width: 10px;
    top: 0.75em;
    position: absolute;
    background-color: currentColor;
    border-radius: 1px;
  }
  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
    left: 0.8em;
  }
`

export const Container = styled.div`
  width: 100%;
  height: 560px;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
`
export const Item = styled.div`
  flex-shrink: 0;
  position: relative;
  width: ${p => p.itemWidth + 'px'};
  height: 560px;
  transition: all 0.6s linear;
  color: #fff;
  overflow: hidden;
  img {
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0;
    max-width: ${p => p.itemWidth + 'px'};
    height: 560px;
    transition: all 0.6s linear;
  }
  button {
    min-width: 120px;
    position: absolute;
    bottom: 80px;
    left: 30px;
    border-radius: 20px;
    opacity: 0;
    transition: all 0.6s linear;
  }
  div {
    position: absolute;
    width: 520px;
    left: 0;
    top: 0;
    padding: 100px 14px 80px;
    h3 {
      font-size: 24px;
      font-weight: bold;
      line-height: 1;
    }
    span {
      position: relative;
      top: 76px;
      opacity: 1;
      font-size: 14px;
      transition: all 0.6s linear;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      line-height: 30px;
      margin-top: 21px;
      opacity: 0;
      transition: all 0.6s linear;
    }
    ol {
      margin-top: 44px;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      li {
        opacity: 0;
        transition: all 0.6s linear;
        padding-left: 0;
        width: 50%;
        margin-bottom: 10px;
        &::before {
          display: none;
        }
        svg {
          width: 14px;
          height: 16px;
          margin-right: 10px;
          path {
            fill: ${p => p.theme.color.secondary};
          }
        }
      }
    }
  }

  ${p =>
    p.active &&
    css`
      width: 520px;
      img {
        max-width: 520px;
      }
      button {
        opacity: 1;
      }
      div {
        padding-left: 30px;
        padding-right: 30px;
        p,
        ol li {
          opacity: 1;
        }
        span {
          opacity: 0;
        }
      }
    `}
`
