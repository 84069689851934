import React from 'react'

const SvgFeature2 = props => (
  <svg viewBox='0 0 50 62' {...props}>
    <defs>
      <style>
        {'.feature2_svg__cls-1{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;stroke:#252a3a}'}
      </style>
    </defs>
    <g id='feature2_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='feature2_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path className='feature2_svg__cls-1' d='M4 61h42a3 3 0 003-3V16L35.5 1H4a3 3 0 00-3 3v54a3 3 0 003 3z' />
        <path
          d='M14.5 29.8C14.5 28.2 25 25 25 25s10.5 3.2 10.5 4.8C35.5 42.6 25 49 25 49s-10.5-6.4-10.5-19.2z'
          stroke='#fd5c1f'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
        />
        <path className='feature2_svg__cls-1' d='M34 1v15h15' />
      </g>
    </g>
  </svg>
)

export default SvgFeature2
