import React from 'react'

const SvgProduct1 = props => (
  <svg viewBox='0 0 38.28 47.35' {...props}>
    <defs>
      <style>
        {'.product1_svg__cls-2{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;stroke:#fd5c1f}'}
      </style>
    </defs>
    <g id='product1_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='product1_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          d='M1 46.35V1h26.08l10.2 11.91v33.44z'
          stroke='#252a3a'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
        />
        <path
          className='product1_svg__cls-2'
          d='M8.94 28.21h6.8v7.94h-6.8zM7.8 36.15h22.68M15.74 22.54h6.8v13.61h-6.8zM22.54 16.87h6.8v19.28h-6.8z'
        />
      </g>
    </g>
  </svg>
)

export default SvgProduct1
