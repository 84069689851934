import styled, { css } from 'styled-components'
import { rotate, transition } from '@raysync/common/theme'

export const Container = styled.div`
  position: relative;
  overflow-x: hidden;
  list-style: none;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
`

export const Wrapper = styled.div`
  transition-duration: ${p => p.duration + 'ms'};
  transform: translate3d(${p => p.x + 'px'}, 0px, 0px);
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  padding-top: 40px;
`

export const Box = styled.div`
  position: relative;
  width: ${p => p.width + 'px'};
  height: 320px;
  text-align: center;
  flex-shrink: 0;
  background-color: #fff;
  margin-right: 30px;
  border-radius: 12px;

  color: #595c67;
  overflow-y: hidden;
  transition: all ${p => p.duration / 1000 + 's'} linear;
  > div {
    position: relative;
    padding: 0 ${p => (p.width > 300 ? '28px' : '15px')};
    transform: translateY(104px);
    > span {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      top: ${p => (p.active ? '286px' : '180px')};
      font-size: 14px;
      line-height: 1;
    }
  }
  div,
  img,
  span,
  a,
  p {
    transition: all ${p => p.duration / 1000 + 's'} ease-in-out;
  }
  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 1;
    color: #252a3a;
  }
  p {
    opacity: 0;
    padding-top: 26px;
    font-size: 14px;
    line-height: 24px;
  }

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    opacity: 0;
    bottom: -40px;
    background-color: ${p => p.theme.color.secondary};
    color: #fff;
    padding: 0 28px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    :hover {
      color: #fff;
    }
    svg {
      width: 12px;
      height: 18px;
      margin: 0;
    }
  }
  ${p =>
    p.active &&
    css`
      height: 400px;
      transform: translateY(-40px);
      p {
        display: block;
        opacity: 1;
      }
      > div {
        transform: translateY(38px);
      }
      a {
        bottom: 0;
        opacity: 1;
      }
      ${IconWrap} {
        transform: translateY(-10px);
        img {
          opacity: 1;
        }
      }
    `}
`
export const IconWrap = styled.div`
  position: relative;
  width: 84px;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  svg {
    display: block;
    height: 48px;
    margin: 0 auto;
  }
  img {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 84px;
    height: 84px;
    animation: ${rotate} 0.8s linear;
    animation-iteration-count: ${p => (p.duration ? 'infinite' : 'initial')};
  }
`
export const MobileWrap = styled.div`
  margin-top: 0.35rem;
`
export const MobileItem = styled.div`
  width: 100%;
  margin-bottom: 0.16rem;
  overflow: hidden;
  max-height: ${p => (p.expanded ? '7rem' : '1rem')};
  transition: ${transition('max-height')};
  border-radius: 0.12rem;
  background-color: #fff;
`

export const ExpandItem = styled.div`
  line-height: 1rem;
  height: 1rem;
  font-size: 0.3rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #252a3a;
    font-weight: bold;
  }
  > div svg {
    width: 0.4rem;
    margin-right: 0.2rem;
  }
  > svg {
    width: 20px;
    height: 36px;
    transform: ${p => (p.expanded ? 'rotate(-90deg)' : 'rotate(90deg)')};
    transition: ${transition('transform')};
  }
`
export const MobileContent = styled.div`
  p {
    padding: 0.2rem 0.35rem 0.58rem;
    color: #595c67;
    font-size: 0.24rem;
    line-height: 1.5;
  }
  div {
    font-size: 0.24rem;
    line-height: 0.6rem;
    height: 0.6rem;
    padding: 0 0.35rem;
    background-color: ${p => p.theme.color.secondary};
    display: flex;
    justify-content: space-between;
    color: #fff;
    a {
      color: #fff;
      font-size: 0.24rem;
      svg {
        transform: scale(0.8);
      }
    }
  }
`
