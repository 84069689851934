import React from 'react'
import intl from 'react-intl-universal'
import { TabWrap, TabHeader, TabTitle } from './atoms'
import TabContent from './content'
import Solution1 from './images/solution1.png'
import Solution2 from './images/solution2.png'
import Solution3 from './images/solution3.png'
import Solution4 from './images/solution4.png'
import Solution5 from './images/solution5.png'
import Solution6 from './images/solution6.png'
import Solution7 from './images/solution7.png'
import Solution8 from './images/solution8.png'
import Solution9 from './images/solution9.png'
import Solution10 from './images/solution10.png'
import Solution11 from './images/solution11.png'
import Solution12 from './images/solution12.png'
import Solution13 from './images/solution13.png'
import Solution14 from './images/solution14.png'
import Solution15 from './images/solution15.png'
import Solution16 from './images/solution16.png'
import MobileSolution1 from './images/m_solution1.png'
import MobileSolution2 from './images/m_solution2.png'
import MobileSolution3 from './images/m_solution3.png'
import MobileSolution4 from './images/m_solution4.png'
import MobileSolution5 from './images/m_solution5.png'
import MobileSolution6 from './images/m_solution6.png'
import MobileSolution7 from './images/m_solution7.png'
import MobileSolution8 from './images/m_solution8.png'
import MobileSolution9 from './images/m_solution9.png'
import MobileSolution10 from './images/m_solution10.png'
import MobileSolution11 from './images/m_solution11.png'
import MobileSolution12 from './images/m_solution12.png'
import MobileSolution13 from './images/m_solution13.png'
import MobileSolution14 from './images/m_solution14.png'
import MobileSolution15 from './images/m_solution15.png'
import MobileSolution16 from './images/m_solution16.png'

class Tab extends React.PureComponent {
  state = {
    topTabActive: 0,
    data: [],
    dataList1: [
      {
        tag: intl.get('home.solution.tab1.item5'),
        title: intl.get('home.solution.tab1.item5.title'),
        desc: intl.get('home.solution.tab1.item5.desc'),
        point1: intl.get('home.solution.tab1.item5.point1'),
        point2: intl.get('home.solution.tab1.item5.point2'),
        point3: intl.get('home.solution.tab1.item5.point3'),
        point4: intl.get('home.solution.tab1.item5.point4'),
        action: 'large-file-transfer',
        pic: Solution1,
        mobilePic: MobileSolution1,
        id: 'Solutions-PLFT',
      },
      {
        tag: intl.get('home.solution.tab1.item6'),
        title: intl.get('home.solution.tab1.item6.title'),
        desc: intl.get('home.solution.tab1.item6.desc'),
        point1: intl.get('home.solution.tab1.item6.point1'),
        point2: intl.get('home.solution.tab1.item6.point2'),
        point3: intl.get('home.solution.tab1.item6.point3'),
        point4: intl.get('home.solution.tab1.item6.point4'),
        action: 'data-transfer',
        pic: Solution2,
        mobilePic: MobileSolution2,
        id: 'Solutions-BFT',
      },
      {
        tag: intl.get('home.solution.tab1.item7'),
        title: intl.get('home.solution.tab1.item7.title'),
        desc: intl.get('home.solution.tab1.item7.desc'),
        point1: intl.get('home.solution.tab1.item7.point1'),
        point2: intl.get('home.solution.tab1.item7.point2'),
        point3: intl.get('home.solution.tab1.item7.point3'),
        point4: intl.get('home.solution.tab1.item7.point4'),
        action: 'multi-transfer',
        pic: Solution3,
        mobilePic: MobileSolution3,
        id: 'Solutions-WWT',
      },
      {
        tag: intl.get('home.solution.tab1.item4'),
        title: intl.get('home.solution.tab1.item4.title'),
        desc: intl.get('home.solution.tab1.item4.desc'),
        point1: intl.get('home.solution.tab1.item4.point1'),
        point2: intl.get('home.solution.tab1.item4.point2'),
        point3: intl.get('home.solution.tab1.item4.point3'),
        point4: intl.get('home.solution.tab1.item4.point4'),
        action: 'data-sync',
        pic: Solution4,
        mobilePic: MobileSolution4,
        id: 'Solutions-AS',
      },
      {
        // 内外网
        tag: intl.get('home.solution.tab1.item8'),
        title: intl.get('home.solution.tab1.item8.title'),
        desc: intl.get('home.solution.tab1.item8.desc'),
        point1: intl.get('home.solution.tab1.item8.point1'),
        point2: intl.get('home.solution.tab1.item8.point2'),
        point3: intl.get('home.solution.tab1.item8.point3'),
        point4: intl.get('home.solution.tab1.item8.point4'),
        action: 'file-exchange',
        pic: Solution5,
        mobilePic: MobileSolution5,
        id: 'Solutions-FE',
      },
      {
        tag: intl.get('home.solution.tab1.item3'),
        title: intl.get('home.solution.tab1.item3.title'),
        desc: intl.get('home.solution.tab1.item3.desc'),
        point1: intl.get('home.solution.tab1.item3.point1'),
        point2: intl.get('home.solution.tab1.item3.point2'),
        point3: intl.get('home.solution.tab1.item3.point3'),
        point4: intl.get('home.solution.tab1.item3.point4'),
        action: 'data-migration',
        pic: Solution6,
        mobilePic: MobileSolution6,
        id: 'Solutions-DM',
      },
      {
        tag: intl.get('home.solution.tab1.item1'),
        title: intl.get('home.solution.tab1.item1.title'),
        desc: intl.get('home.solution.tab1.item1.desc'),
        point1: intl.get('home.solution.tab1.item1.point1'),
        point2: intl.get('home.solution.tab1.item1.point2'),
        point3: intl.get('home.solution.tab1.item1.point3'),
        point4: intl.get('home.solution.tab1.item1.point4'),
        action: 'data-collaboration',
        pic: Solution7,
        mobilePic: MobileSolution7,
        id: 'Solutions-DS',
      },
      {
        tag: intl.get('home.solution.tab1.item2'),
        title: intl.get('home.solution.tab1.item2.title'),
        desc: intl.get('home.solution.tab1.item2.desc'),
        point1: intl.get('home.solution.tab1.item2.point1'),
        point2: intl.get('home.solution.tab1.item2.point2'),
        point3: intl.get('home.solution.tab1.item2.point3'),
        point4: intl.get('home.solution.tab1.item2.point4'),
        action: 'data-distribution',
        pic: Solution8,
        mobilePic: MobileSolution8,
        id: 'Solutions-DD',
      },
    ],
    dataList2: [
      {
        tag: intl.get('home.solution.tab2.item1'),
        title: intl.get('home.solution.tab2.item1.title'),
        desc: intl.get('home.solution.tab2.item1.desc'),
        point1: intl.get('home.solution.tab2.item1.point1'),
        point2: intl.get('home.solution.tab2.item1.point2'),
        point3: intl.get('home.solution.tab2.item1.point3'),
        point4: intl.get('home.solution.tab2.item1.point4'),
        action: 'industry-government',
        pic: Solution9,
        mobilePic: MobileSolution9,
        id: 'Solutions-Government',
      },
      {
        tag: intl.get('home.solution.tab2.item2'),
        title: intl.get('home.solution.tab2.item2.title'),
        desc: intl.get('home.solution.tab2.item2.desc'),
        point1: intl.get('home.solution.tab2.item2.point1'),
        point2: intl.get('home.solution.tab2.item2.point2'),
        point3: intl.get('home.solution.tab2.item2.point3'),
        point4: intl.get('home.solution.tab2.item2.point4'),
        action: 'industry-ad',
        pic: Solution10,
        mobilePic: MobileSolution10,
        id: 'Solutions-Media',
      },
      {
        tag: intl.get('home.solution.tab2.item3'),
        title: intl.get('home.solution.tab2.item3.title'),
        desc: intl.get('home.solution.tab2.item3.desc'),
        point1: intl.get('home.solution.tab2.item3.point1'),
        point2: intl.get('home.solution.tab2.item3.point2'),
        point3: intl.get('home.solution.tab2.item3.point3'),
        point4: intl.get('home.solution.tab2.item3.point4'),
        action: 'industry-financing',
        pic: Solution11,
        mobilePic: MobileSolution11,
        id: 'Solutions-Financing',
      },
      {
        tag: intl.get('home.solution.tab2.item4'),
        title: intl.get('home.solution.tab2.item4.title'),
        desc: intl.get('home.solution.tab2.item4.desc'),
        point1: intl.get('home.solution.tab2.item4.point1'),
        point2: intl.get('home.solution.tab2.item4.point2'),
        point3: intl.get('home.solution.tab2.item4.point3'),
        point4: intl.get('home.solution.tab2.item4.point4'),
        action: 'industry-gene',
        pic: Solution12,
        mobilePic: MobileSolution12,
        id: 'Solutions-Gene',
      },
      {
        tag: intl.get('home.solution.tab2.item5'),
        title: intl.get('home.solution.tab2.item5.title'),
        desc: intl.get('home.solution.tab2.item5.desc'),
        point1: intl.get('home.solution.tab2.item5.point1'),
        point2: intl.get('home.solution.tab2.item5.point2'),
        point3: intl.get('home.solution.tab2.item5.point3'),
        point4: intl.get('home.solution.tab2.item5.point4'),
        action: 'industry-car',
        pic: Solution13,
        mobilePic: MobileSolution13,
        id: 'Solutions-Car',
      },
      {
        tag: intl.get('home.solution.tab2.item6'),
        title: intl.get('home.solution.tab2.item6.title'),
        desc: intl.get('home.solution.tab2.item6.desc'),
        point1: intl.get('home.solution.tab2.item6.point1'),
        point2: intl.get('home.solution.tab2.item6.point2'),
        point3: intl.get('home.solution.tab2.item6.point3'),
        point4: intl.get('home.solution.tab2.item6.point4'),
        action: 'industry-manufacturing',
        pic: Solution14,
        mobilePic: MobileSolution14,
        id: 'Solutions-Manufacturing',
      },
      {
        tag: intl.get('home.solution.tab2.item7'),
        title: intl.get('home.solution.tab2.item7.title'),
        desc: intl.get('home.solution.tab2.item7.desc'),
        point1: intl.get('home.solution.tab2.item7.point1'),
        point2: intl.get('home.solution.tab2.item7.point2'),
        point3: intl.get('home.solution.tab2.item7.point3'),
        point4: intl.get('home.solution.tab2.item7.point4'),
        action: 'industry-internet',
        pic: Solution15,
        mobilePic: MobileSolution15,
        id: 'Solutions-Internet',
      },
      {
        tag: intl.get('home.solution.tab2.item8'),
        title: intl.get('home.solution.tab2.item8.title'),
        desc: intl.get('home.solution.tab2.item8.desc'),
        point1: intl.get('home.solution.tab2.item8.point1'),
        point2: intl.get('home.solution.tab2.item8.point2'),
        point3: intl.get('home.solution.tab2.item8.point3'),
        point4: intl.get('home.solution.tab2.item8.point4'),
        action: 'industry-moive',
        pic: Solution16,
        mobilePic: MobileSolution16,
        id: 'Solutions-Moive',
      },
    ],
  }
  triggerTopTabActive(index) {
    this.setState({ topTabActive: index })
  }
  render() {
    const { topTabActive, dataList1, dataList2 } = this.state
    return (
      <TabWrap>
        <TabHeader>
          <TabTitle active={topTabActive === 0} onClick={this.triggerTopTabActive.bind(this, 0)}>
            {intl.get('home.solution.tab1')}
          </TabTitle>
          <TabTitle active={topTabActive === 1} onClick={this.triggerTopTabActive.bind(this, 1)}>
            {intl.get('home.solution.tab2')}
          </TabTitle>
        </TabHeader>
        <TabContent key={topTabActive} data={topTabActive === 0 ? dataList1 : dataList2} />
      </TabWrap>
    )
  }
}

export default Tab
