import React from 'react'

const SvgFast = props => (
  <svg viewBox='0 0 16 18' {...props}>
    <path
      d='M8 0L0 2.3v4.6c0 5 3.2 9.5 8 11.1 4.8-1.6 8-6 8-11.1V2.3L8 0zm3.8 8.5l-3.5 5.3c-.2.1-.3.2-.5.2s-.4-.2-.4-.4v-3.3H4.6c-.1 0-.2 0-.2-.1-.2-.2-.3-.5-.2-.7l3.5-5.3c.2-.1.3-.2.4-.2.2 0 .4.2.4.4v3.3h2.8c.1 0 .2 0 .2.1.3.2.4.5.3.7z'
      fill='#636772'
    />
  </svg>
)

export default SvgFast
