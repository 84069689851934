import React from 'react'

const SvgFeature3 = props => (
  <svg viewBox='0 0 66.79 62' {...props}>
    <defs>
      <style>
        {
          '.feature3_svg__cls-1,.feature3_svg__cls-2{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}.feature3_svg__cls-1{stroke:#fd5c1f}.feature3_svg__cls-2{stroke:#252a3a}'
        }
      </style>
    </defs>
    <g id='feature3_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='feature3_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <circle className='feature3_svg__cls-1' cx={58.84} cy={7.95} r={6.95} />
        <circle className='feature3_svg__cls-1' cx={58.84} cy={54.05} r={6.95} />
        <path className='feature3_svg__cls-2' d='M51.89 31H40.81' />
        <path className='feature3_svg__cls-1' d='M58.84 14.9v9.15M58.84 37.95v9.15' />
        <circle className='feature3_svg__cls-2' cx={58.84} cy={31} r={6.95} />
        <path
          className='feature3_svg__cls-2'
          d='M1 52.53V9.31M40.82 52.53V9.31M40.82 9.31c0 2.31-8.92 4.19-19.91 4.19S1 11.62 1 9.31M1 9.31C1 7 9.91 5.11 20.91 5.11S40.82 7 40.82 9.31M40.82 20.11c0 2.32-8.92 4.2-19.91 4.2S1 22.43 1 20.11M40.82 30.92c0 2.32-8.92 4.19-19.91 4.19S1 33.24 1 30.92M40.82 41.72c0 2.32-8.92 4.2-19.91 4.2S1 44 1 41.72M40.82 52.53c0 2.32-8.92 4.2-19.91 4.2S1 54.85 1 52.53'
        />
      </g>
    </g>
  </svg>
)

export default SvgFeature3
