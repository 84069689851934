import React from 'react'

const SvgFeature4 = props => (
  <svg viewBox='0 0 68.67 62' {...props}>
    <defs>
      <style>
        {
          '.feature4_svg__cls-1,.feature4_svg__cls-2{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}.feature4_svg__cls-1{stroke:#252a3a}.feature4_svg__cls-2{stroke:#fd5c1f}'
        }
      </style>
    </defs>
    <g id='feature4_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='feature4_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='feature4_svg__cls-1'
          d='M34.33 47.67V21M7.67 47.67V34.33H61v13.34M1 47.67h13.33V61H1zM54.33 47.67h13.33V61H54.33zM27.67 47.67H41V61H27.67z'
        />
        <path className='feature4_svg__cls-2' d='M7.67 1H61v20H7.67zM17.67 11H21' />
      </g>
    </g>
  </svg>
)

export default SvgFeature4
